<template>
  <div v-loading="loading" style="min-height: 100px">
    <el-table
        ref="table"
        :data="data"
        border
        fit
        size="mini"
        class="main-table">
        <el-table-column
          prop="description"
          header-align="center"
          min-width="500px"
          label="Описание">
        </el-table-column>
      <el-table-column
        prop="formula"
        header-align="center"
        width="200px"
        align="center"
        label="Обозначение">
        <template slot-scope="scope">
          <katex-element v-if="scope.row.formula.indexOf('`') >= 0" :expression="getFormula(scope.row.formula)"/>
          <div v-else>{{ scope.row.formula }}</div>
        </template>
      </el-table-column>
        <el-table-column
          prop="value"
          header-align="center"
          align="center"
          width="100px"
          label="Значение">
        </el-table-column>
        <el-table-column
          prop="type_name"
          header-align="center"
          align="center"
          width="100px"
          label="В чём измеряется">
        </el-table-column>
      </el-table>
  </div>
</template>

<script>
import { getRatingSymbolDetail } from '@/api/api'
import AsciiMathParser from 'asciimath2tex'

export default {
  props: {
    storageId: {
      required: true
    }
  },
  data () {
    return {
      data: [],
      loading: false
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    getFormula (val) {
      if (val.indexOf('`') >= 0) {
        val = val.replaceAll('`', '')
        const parser = new AsciiMathParser()
        return parser.parse(val).replaceAll('∑', 'Σ')
      }
      return val
    },
    fetchData () {
      this.loading = true
      getRatingSymbolDetail({
        storage_id: this.storageId
      }).then(response => {
        this.data = response.data.data
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.el-table >>> .katex {
  font-size: 1.5em;
}

.el-table >>> .cell {
  line-height: 17px;
}

.el-table >>> .el-input__suffix {
  right: 10px;
}

</style>
