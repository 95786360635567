<template>
  <div style="margin: 20px">
    <label style="line-height: 40px">За период</label>
    <el-select
      v-model="params.period_id"
      placeholder="Выберите период"
      style="margin-left: 20px"
      @change="fetchData">
      <el-option
        v-for="item in periods"
        :key="item.id"
        :label="item.title"
        :value="item.id">
      </el-option>
    </el-select>
    <el-checkbox
      v-model="params.not_rating"
      style="float: right; line-height: 40px"
      @change="fetchData">
      Показывать показатели не участвующие в рейтинге
    </el-checkbox>
    <el-divider/>
    <div v-loading="loading">
      <rating-table
        :data="data"
        name="ratingtable"
        :not-rating="params.not_rating"
        :period-id="params.period_id"
        @reload="fetchData" />
    </div>
  </div>
</template>

<script>
import RatingTable from '@/components/RatingTable'
import { getRating } from '@/api/api'
import { getPeriods } from '@/api/catalogs'

export default {
  components: {
    RatingTable
  },
  data () {
    return {
      viceOptions: [],
      params: {
        period_id: null,
        not_rating: false
      },
      periods: [],
      viceGovernors: [],
      loading: true,
      sources: [],
      iogv: [],
      data: []
    }
  },
  beforeMount () {
    getPeriods({ is_monthly: false, started: true }).then((response) => {
      this.periods = response.data.data
    })
  },
  mounted () {
    if (this.$route.query.period_id) {
      this.params.period_id = parseInt(this.$route.query.period_id)
    }
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.loading = true
      getRating(this.params).then(response => {
        this.data = response.data.data
        this.params.period_id = response.data.searchModel.period_id
        this.loading = false
      })
    }
  }
}

</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.desktop-cols {
  padding: 5px;
}

.chart {
  z-index: 100;
}
</style>
