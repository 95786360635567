<template>
  <div>
    <div class="length-text">
      <el-row>
        <el-col :span="12" style="
          height: 40px;
          display: flex;
          align-items: flex-end;">
          <span>Всего {{ list.length }} {{ getSpell(list.length)}}</span>
        </el-col>
        <el-col :span="12" style="text-align: right">
          <el-button v-loading="disableExportBtn" @click="downloadExport(1)">
            <a title="Сгенерировать выгрузку по Общим КПР">
              <i class="fas fa-download" style="margin-right: 5px"/>
              Общие КПР
            </a>
          </el-button>
          <el-button v-loading="disableExportBtn" @click="downloadExport(2)">
            <a title="Сгенерировать выгрузку по Индивидуальным КПР">
              <i class="fas fa-download" style="margin-right: 5px"/>
              Индивидуальные КПР
            </a>
          </el-button>
          <el-button v-loading="disableExportBtn" @click="downloadExport(3)">
            <a title="Сгенерировать выгрузку по КПР КНД">
              <i class="fas fa-download" style="margin-right: 5px"/>
              КПР КНД
            </a>
          </el-button>
        </el-col>
      </el-row>
    </div>
    <el-table
      v-if="showTable"
      ref="table"
      :data="list"
      border
      fit
      size="mini"
      class="main-table">
      <el-table-column
        type="expand">
        <template slot-scope="props">
          <row-detail :ebosp-id="props.row.ebosp_id" :not-rating="notRating" :period-id="periodId"/>
        </template>
      </el-table-column>
      <el-table-column
        prop="ebosp_short_name"
        header-align="center"
        sortable
        label="ИОГВ">
      </el-table-column>
      <el-table-column
        label="Количество учитываемых КПР"
        header-align="center">
        <el-table-column
          prop="result_count"
          align="center"
          header-align="center"
          sortable
          label="Всего">
        </el-table-column>
        <el-table-column
          prop="overall_count"
          align="center"
          header-align="center"
          sortable
          label="Общих">
        </el-table-column>
        <el-table-column
          prop="individual_count"
          align="center"
          header-align="center"
          sortable
          label="Индивидуальных">
        </el-table-column>
        <el-table-column
          prop="control_count"
          align="center"
          header-align="center"
          sortable
          label="КНД">
        </el-table-column>
      </el-table-column>
      <el-table-column
        label="Итог по общим КПР"
        header-align="center">
        <el-table-column
          prop="overall"
          align="center"
          header-align="center"
          sortable
          label="Итоговый балл">
        </el-table-column>
      </el-table-column>
      <el-table-column
        label="Итог по индивидуальным КПР, утвержденным приказами ИОГВ"
        header-align="center">
        <el-table-column
          prop="individual"
          align="center"
          header-align="center"
          sortable
          label="Итоговый балл">
        </el-table-column>
        <el-table-column
          prop="control"
          align="center"
          header-align="center"
          sortable
          label="Итоговый балл по КПР КНД">
        </el-table-column>
      </el-table-column>
      <el-table-column
        prop="rating"
        align="center"
        header-align="center"
        sortable
        label="Рейтинговый балл">
      </el-table-column>
      <el-table-column
        prop="place"
        align="center"
        header-align="center"
        sortable
        label="Место в рейтинге">
      </el-table-column>
      <el-table-column
        v-if="userCan(['control'])"
        label="Обнуление"
        header-align="center">
        <template slot-scope="scope">
          <div v-if="(scope.row.control_count + scope.row.individual_count) > 0">
            <el-button
              v-if="(scope.row.control + scope.row.individual) > 0"
              type="danger"
              size="mini"
              @click="openDialog(scope.row.ebosp_id)">
              Обнулить
            </el-button>
            <el-button v-else type="success" size="mini" @click="validateKP(scope.row.ebosp_id)">Восстановить</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="Основание для обнуления"
      :visible.sync="dialogVisible"
      width="30%">
      <el-form :model="dialogForm" ref="form">
        <el-form-item
          prop="file_id"
          :rules="{ required: true, message: 'Не заполнено обязательное поле', trigger: ['change', 'blur'] }">
          <file-upload v-model="dialogForm.file_id" @delete="deleteFile"
                       @file-uploaded="fileUploaded" :limit="1"/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="invalidateKP" :disabled="lockButton">Отправить</el-button>
        <el-button @click="dialogVisible = false">Отменить</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import RowDetail from '@/components/RatingTable/RowDetail'
import FileUpload from '@/components/FileUpload/Files'
import { invalidateKP, validateKP } from '@/api/api'
import userCan from '@/utils/checkPermission'
import { getExport } from '@/api/file'
import { saveFile } from '@/utils'

export default {
  components: {
    RowDetail,
    FileUpload
  },
  props: {
    name: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    notRating: {
      type: Boolean,
      required: true
    },
    periodId: {
      required: true
    }
  },
  data () {
    return {
      showTable: true,
      dialogVisible: false,
      lockButton: false,
      disableExportBtn: false,
      disablePrintBtn: false,
      dialogForm: {
        period_id: null,
        ebosp_id: null,
        file_id: null
      }
    }
  },
  computed: {
    list () {
      return this.data ?? []
    }
  },
  methods: {
    getSpell (val) {
      if (val % 100 < 20 && val % 100 > 10) {
        return 'записей'
      }
      if (val % 10 === 1) {
        return 'запись'
      } else if (val % 10 < 5 && val % 10 > 1) {
        return 'записи'
      }
      return 'записей'
    },
    userCan: (roles) => userCan(roles),
    invalidateKP () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.lockButton = true
          invalidateKP(this.dialogForm).then((response) => {
            this.$message({
              message: 'Успешно обнулено!',
              type: 'success'
            })
          }).finally(() => {
            this.lockButton = false
            this.dialogVisible = false
            this.dialogForm.period_id = null
            this.dialogForm.file_id = null
            this.dialogForm.ebosp_id = null
            this.$emit('reload')
          })
        }
      })
    },
    validateKP (ebospId) {
      this.dialogForm.ebosp_id = ebospId
      this.dialogForm.period_id = this.periodId
      validateKP(this.dialogForm).then((response) => {
        this.$message({
          message: 'Успешно восстановлено!',
          type: 'success'
        })
      }).finally(() => {
        this.dialogForm.period_id = null
        this.dialogForm.file_id = null
        this.dialogForm.ebosp_id = null
        this.$emit('reload')
      })
    },
    openDialog (ebospId) {
      this.dialogForm.ebosp_id = ebospId
      this.dialogForm.period_id = this.periodId
      this.dialogVisible = true
    },
    deleteFile () {
      this.dialogForm.file_id = null
      this.$refs.form.validateField('file_id')
    },
    fileUploaded (id) {
      this.dialogForm.file_id = id
      this.$refs.form.validateField('file_id')
    },
    downloadExport (type) {
      this.disableExportBtn = true
      getExport({
        period_id: this.periodId,
        type
      }).then(response => {
        const fileName = response.headers['content-disposition'].split('filename*=utf-8\'\'')[1].split(';')[0]
        saveFile(response.data, decodeURI(fileName))
      }).finally(() => {
        this.disableExportBtn = false
      })
    }
  }
}
</script>

<style scoped>

.main-table >>> .el-table__header-wrapper {
  /* need for sticky header */
  top: 50px;
  z-index: 1;
}

.el-table >>> .cell {
  line-height: 17px;
}

.el-table >>> .el-input__suffix {
  right: 10px;
}

</style>
