<template>
  <div v-loading="loading" style="min-height: 100px">
    <div v-for="(value, name) in classification" :key="name">
      <div v-if="data.filter(x => x.classification_id == name).length > 0">
        <label style="line-height: 1.5rem; margin-left: 10px">{{ value }}</label>
        <el-table
          ref="table"
          :data="data.filter(x => x.classification_id == name)"
          border
          fit
          size="mini"
          :row-class-name="getRowClass"
          class="main-table">
          <el-table-column
            type="expand">
            <template slot-scope="props">
              <symbol-detail :storage-id="props.row.id" />
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            header-align="center"
            min-width="500px"
            label="КПР">
          </el-table-column>
          <el-table-column
            prop="pki_index"
            header-align="center"
            align="center"
            width="70px"
            label="Индекс КПР">
          </el-table-column>
          <el-table-column
            prop="formula"
            header-align="center"
            min-width="300px"
            align="center"
            label="Расчет">
            <template slot-scope="scope">
              <katex-element v-if="scope.row.formula.indexOf('`') >= 0" :expression="getFormula(scope.row.formula)"/>
              <div v-else>{{ scope.row.formula }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="result"
            header-align="center"
            align="center"
            width="100px"
            label="Результат">
            <template slot-scope="scope">
              <span v-if="scope.row.rule === 1">
                {{ scope.row.result ? (scope.row.result !== '-' ? `${scope.row.result} ${scope.row.unit_of_measurement}` : '-') : '' }}
              </span>
              <span v-else>
                {{ scope.row.result }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="rating"
            header-align="center"
            align="center"
            width="70px"
            label="Балл">
          </el-table-column>
          <el-table-column
            prop="fio"
            header-align="center"
            label="Кто заполнил">
          </el-table-column>
          <el-table-column
            v-if="showActionColumn"
            align="center"
            width="100px">
            <template slot-scope="scope">
              <a v-if="scope.row.can_view" @click="$router.push({ name: '/storage/view', params: { id: scope.row.id}})">
                <i class="fas fa-eye" style="margin-right: 10px"/>
              </a>
              <a v-if="scope.row.can_edit" @click="$router.push({ name: '/storage/update', params: { id: scope.row.id}})">
                <i class="fas fa-edit"/>
              </a>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import SymbolDetail from '@/components/RatingTable/SymbolDetail'
import { getRatingRowDetail } from '@/api/api'
import AsciiMathParser from 'asciimath2tex'

export default {
  components: {
    SymbolDetail
  },
  props: {
    periodId: {
      required: true
    },
    notRating: {
      required: true
    },
    ebospId: {
      required: true
    }
  },
  data () {
    return {
      data: [],
      loading: false,
      showActionColumn: true,
      classification: {}
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    getRowClass ({ row }) {
      if (row.rule !== 1) {
        return 'no-expand-arrow'
      }
    },
    getFormula (val) {
      if (val.indexOf('`') >= 0) {
        val = val.replaceAll('`', '')
        const parser = new AsciiMathParser()
        return parser.parse(val).replaceAll('∑', 'Σ')
      }
      return val
    },
    fetchData () {
      this.loading = true
      getRatingRowDetail({
        period_id: this.periodId,
        ebosp_id: this.ebospId,
        not_rating: this.notRating
      }).then(response => {
        this.data = response.data.data
        this.classification = response.data.classification
        this.showActionColumn = response.data.showActionColumn
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.el-table >>> .katex {
  font-size: 1.5em;
}

.el-table >>> .cell {
  line-height: 17px;
}

.el-table >>> .el-input__suffix {
  right: 10px;
}
</style>
